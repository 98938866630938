var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex search",
      class: {
        _show: _vm.showSearch,
        "_dropdown-is-open": _vm.dropdownIsOpen,
      },
    },
    [
      _c(
        "CButton",
        {
          staticClass:
            "shadow-none search__btn flex-shrink-0 align-items-center",
          attrs: { variant: _vm.searchBtnVariant, color: "primary" },
          on: {
            click: function ($event) {
              _vm.isMobile
                ? (_vm.showSearch = !_vm.showSearch)
                : _vm.focusSearch(true)
            },
          },
        },
        [
          _c("CIcon", {
            staticClass: "my-0 mr-1",
            attrs: { name: "cil-search" },
          }),
          _c("span", [_vm._v(_vm._s(_vm.$t("screener.search")))]),
        ],
        1
      ),
      _c("v-select", {
        ref: "vselect",
        staticClass: "v-select-screener flex-grow-1 flex-shrink-1",
        attrs: {
          options: _vm.coinList,
          clearSearchOnBlur: () => false,
          closeOnSelect: false,
          clearSearchOnSelect: true,
          getOptionLabel: (option) => option[_vm.optionName],
          filter: _vm.filterSearch,
          multiple: "",
          placeholder: _vm.placeholder || _vm.$t("screener.searchPlaceholder"),
        },
        on: {
          search: _vm.search,
          open: _vm.openDropDown,
          close: function ($event) {
            _vm.dropdownIsOpen = false
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "no-options",
              fn: function ({ searching }) {
                return [
                  searching
                    ? [
                        _vm._v(
                          " " + _vm._s(_vm.$t("general.emptySearch")) + " "
                        ),
                      ]
                    : _c("em", { staticStyle: { opacity: "0.5" } }, [
                        _vm._v(_vm._s(_vm.$t("screener.typing"))),
                      ]),
                ]
              },
            },
            {
              key: "option",
              fn: function (option) {
                return [
                  _vm.showDropdown
                    ? _c(
                        "div",
                        {
                          staticClass: "v-select-option-list rounded-pill",
                          class: {
                            _disabled:
                              _vm.disabledOptionKey &&
                              option[_vm.disabledOptionKey],
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(option[_vm.optionName]))])]
                      )
                    : _c("span"),
                ]
              },
            },
            _vm.showListHeader
              ? {
                  key: "list-header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "watch-lists" },
                        [
                          _vm.futuresList.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "v-select-option-list rounded-pill bg-base-card-bg",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setList(_vm.futuresList)
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isFutures ? "Spot" : "Futures"
                                      ) +
                                        " " +
                                        _vm._s(
                                          `Coins:${_vm.futuresList.length}`
                                        )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._l(_vm.watchLists, function (item, i) {
                            return [
                              item.name && item.pairs && item.pairs.length
                                ? _c(
                                    "div",
                                    {
                                      key: i,
                                      staticClass:
                                        "v-select-option-list rounded-pill _watchlist",
                                      class: item.color
                                        ? `bg-${item.color}`
                                        : "",
                                      style: { background: item.customColor },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setList(
                                            item.pairs,
                                            item.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `${item.name} Coins:${item.pairs.length}`
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "vs__deselect",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.resetWatchList(i)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "10",
                                                height: "10",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  d: "M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            !_vm.isMobile
              ? {
                  key: "open-indicator",
                  fn: function () {
                    return [
                      _vm.selectValue && _vm.selectValue.length
                        ? _c(
                            "button",
                            {
                              staticClass: "v-select-option-reset rounded-pill",
                              on: {
                                click: function ($event) {
                                  _vm.selectValue = []
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("screener.searchClear")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectValue,
          callback: function ($$v) {
            _vm.selectValue = $$v
          },
          expression: "selectValue",
        },
      }),
      _c("transition", { attrs: { appear: "", name: "fade" } }, [
        _vm.isMobile && _vm.showSearch
          ? _c(
              "button",
              {
                staticClass: "clear-on-mobile",
                on: {
                  click: function ($event) {
                    _vm.selectValue = []
                  },
                },
              },
              [_c("CIcon", { attrs: { name: "cil-x" } })],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { appear: "", name: "fade" } }, [
        _vm.isMobile && _vm.showSearch
          ? _c("div", {
              staticClass: "search__bg",
              on: {
                click: function ($event) {
                  _vm.showSearch = false
                },
              },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }